*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  max-width: 100vw;
  font-size: 16px;
  font-family: sans-serif;
  overscroll-behavior: none;
}

button,
input {
  cursor: pointer;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 40px;
  height: 40px;
  /* height: 100%;
  width: 100%; */
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid #383636;
  /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding-left: 12px;
}

.App {
  width: 100%;
  /* height: 100%; */
  /* height: 100vh; */
  min-height: 100vh;
  background-color: #FFFFFF;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  /* flex-flow: column; */
}

/* navbar */
.nav-bar {
  position: fixed;
  padding: 10px 30px;
  width: 100%;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: space-between;
  background-color: #cfe4ef;
  color: #242443;
  height: 50px;
}

.sign-in {
  border: none;
  background-color: transparent;
}

.sign-in>img {
  height: 30px;
  width: auto;
}

.sign-out {
  padding: 5px 10px;
  border-radius: 5px;
  color: #88dded;
  border: 1px solid #1c2c4c;
  background-color: #1c2c4c;
  font-weight: 600;
}

/* welcome page */
.welcome {
  padding: 30px;
  text-align: center;
  margin-top: 40px;
  color: #7cc5d9;
}

.welcome :is(h2, p, img) {
  margin-bottom: 20px;
}

/* .messages-list {
  flex: 1;
  overflow: auto;
} */

/* chat component */
.messages-wrapper {
  padding: 20px;
  /* display:block; */
  margin-top: 50px;
  margin-bottom: 60px;
  /* height: calc 100vh - 110px; */
  min-height: 100vb;
  /* min-height: 100vb; */
  /* height: auto; */
  display: flex;
  /* flex: 1 1 auto; */
  /* box-sizing: border-box; */
  flex: 1;
  flex-direction: column-reverse;
  /* overflow: auto; */
  /* background-color: burlywood; */
}

/* .chat-box {
  height: 100vh;
  display: flex;
  flex-direction: column;
} */

.chat-bubble {
  border-radius: 20px 20px 20px 0;
  padding: 14px;
  background-color: #edf7ff;
  color: #1c2c4c;
  width: max-content;
  max-width: calc(100% - 12px);
  /* box-shadow: -2px 2px 1px 1px #FFFFFF; */
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
  margin-bottom: 3px;
  white-space: pre-line;
}

.chat-bubble.right {
  margin-left: auto;
  border-radius: 20px 20px 0 20px;
  background-color: #cfeaf2;
  /* box-shadow: -2px 2px 1px 1px #88dded; */
}

.chat-bubble.suggestions {
  color: #fff;
  /* border: 1px solid #7cc5d9; */
  background-color: #7cc5d9;
  border-radius: 4px;
  margin-left: 30%;
  font-size: 0.9em;
  margin-right: 0px;
  margin-bottom: 0.5px;
  margin-top: 0.5px;
  padding: 7px 12px;
  /* min-width: 150px; */
  /* min-height: 34px; */
  width: 70%;
  text-align: start;
  vertical-align: middle;
  border: none !important;
}

.chat-bubble.suggestionsheader {
  color: darkgray;
  /* border: 1px solid #7cc5d9; */
  background-color: transparent;
  border-radius: 20px 20px 20px 20px;
  margin-left: 30%;
  font-size: 0.7em;
  margin-right: 0px;
  margin-bottom: 1px;
  margin-top: 0px;
  padding: 0px 1px 3px 8px;
  /* min-width: 200px; */
}

.chat-bubble__left {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #1c2c4c;
}

.user-message {
  word-break: break-word;
}

.message-time {
  display: block;
  text-align: right;
}

/* send message */
.send-message {
  position: fixed;
  /* display:block; */
  bottom: 0px;
  width: 100%;
  padding: 16px 18px 16px 18px;
  background-color: #cfe4ef;
  display: flex;
}

/* .send-message>input {
  height: 40px;
  padding: 10px 10px;
  border-radius: 5px 0 0 5px;
  border: none;
  flex-grow: 1;
  background-color: white;
  color: #1c2c4c;
  font-size: 1rem;
  resize: none;
} */

.builtby {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 1px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 0.68rem;
}

.textareainput {
  /* height: 40px; */
  /* height: 40px;
  max-height: 110px; */
  height: 40px;
  max-height: 72px;
  padding: 10px 10px;
  border-radius: 5px 0 0 5px;
  border: none;
  flex-grow: 1;
  background-color: white;
  color: #1c2c4c;
  /* This must be at least this size or mobile safari will resize the whole window when the input is focused */
  font-size: 1rem;
  resize: none;
  border: none;
  outline: none;
  /* font-size: 0.9em; */
  /* overflow: auto; */
  /* max-lines: 3; */
  /* overflow-y: hidden; */
}

.send-message>input:placeholder {
  color: #ddd;
}

.send-message> :is(input, button):focus {
  outline: none;
  border-bottom: 1px solid #7cc5d9;
}

.send-message>button {
  width: 64px;
  height: 40px;
  padding: 5px 10px;
  border-radius: 0 5px 5px 0;
  color: #fff;
  border: 1px solid #7cc5d9;
  background-color: #7cc5d9;
  font-weight: 600;
}

.suggestionbutton {
  /* width: 64px; */
  /* height: 40px; */
  /* padding: 5px 10px; */
  /* border-radius: 0 5px 5px 0; */
  /* color: yellow; */
  /* border: 1px solid #7cc5d9; */
  /* background-color: #7cc5d9; */
  /* font-weight: 600; */
  /* position: fixed; */
  /* display:block; */
  /* bottom: 0px; */
  /* width: 100%; */
  /* padding: 16px 18px 16px 18px; */
  /* background-color: #A4CFE6; */
  /* display: flex; */

  /* border: 1px solid #7cc5d9; */
  /* margin-left: auto; */
  /* width: 100%; */
  /* height: 100%; */
  background: transparent;
  border: none !important;
}

a.pay-button {
  background-color: #7cc5d9;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
}

a.pay-button:hover {
  background-color: #4c6ef5;
}

a.pay-button.container {
  align-items: left;
  background-color: yellow;
}